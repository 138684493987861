import { BrowserRouter as Router } from 'react-router-dom';
import { Loading } from 'components/Loading';
import { ErrorFallback } from 'routes';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { AppRoutes } from 'routes/AppRoutes';
import { useAuth } from 'react-oidc-context';

const App = () => {
  const auth = useAuth();

  const loadingComponent = (
    <Loading size="xl" variant="bars" text="Loading..." height="30vh" />
  );

  switch (auth.activeNavigator) {
    case 'signinRedirect':
    case 'signinSilent':
    case 'signoutRedirect':
    case 'signoutSilent':
      return loadingComponent;
  }

  if (auth.isLoading) return loadingComponent;

  return (
    <Suspense fallback={<Loading size="xl" variant="bars" height="30vh" />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <AppRoutes />
        </Router>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;

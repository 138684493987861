import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { useGetAccountQuery } from 'services/api/account';
import { setAuthToken, setCurrentUser } from 'redux/accountSlice';

interface PrivateRouteProps {
  allowedRoles: string[];
}

export const PrivateRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: currentUser } = useGetAccountQuery(undefined, {
    skip: !auth.isAuthenticated,
  });
  const dispatch = useAppDispatch();

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (auth.user?.access_token)
      dispatch(setAuthToken(auth.user?.access_token));
  }, [auth.user?.access_token, dispatch]);

  useEffect(() => {
    if (currentUser?.userId) dispatch(setCurrentUser(currentUser));
  }, [currentUser, dispatch]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      void auth
        .signinRedirect()
        .catch((error) =>
          console.error('Error during signin redirect:', error),
        );
      setHasTriedSignin(true);
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    hasTriedSignin,
  ]);

  useEffect(() => {
    const validateUser = async () => {
      if (currentUser?.roles) {
        const hasRequiredRole = currentUser.roles.some((role: string) =>
          allowedRoles.includes(role),
        );
        if (!hasRequiredRole) {
          navigate('/unauthorized', {
            state: { from: location },
            replace: true,
          });
        }
      }
    };

    validateUser();
  }, [auth.isAuthenticated, currentUser, allowedRoles, location, navigate]);

  return <Outlet />;
};

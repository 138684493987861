import {
  Image,
  Stack,
  Text,
  Button,
  Box,
  Anchor,
  createStyles,
} from '@mantine/core';
import Icon401Error from 'icons/Icon401Error/401 Error Unauthorized-rafiki.svg';
import { SignInLayout } from 'features/Auth/layouts/SignInLayout';
import { EAppUrls } from 'types';
import { useTranslation } from 'react-i18next';
import useLogout from 'hooks/useLogout';

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: 430,
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    maxWidth: 400,
    height: 400,
    alignSelf: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.white,
    textDecoration: 'none',
  },
  btn: {
    borderRadius: 8,
    width: '100%',
  },
}));

export const UnauthorizedPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { handleLogout } = useLogout();

  return (
    <SignInLayout>
      <Stack spacing={10} className={classes.root}>
        <Box className={classes.img}>
          <Image src={Icon401Error} alt="icon 401 error" />
        </Box>

        <Stack>
          <Text align="center">{t('alert.NotAllowedMessage')}</Text>

          <Anchor
            href={`${EAppUrls.professionalUrl}/dashboard`}
            className={classes.btnContainer}
            target="_blank"
          >
            <Button
              radius="md"
              size="md"
              className={classes.btn}
              onClick={() => handleLogout()}
            >
              {t('alert.ContinueButton')}
            </Button>
          </Anchor>
        </Stack>
      </Stack>
    </SignInLayout>
  );
};

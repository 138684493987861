import { useAuth } from 'react-oidc-context';
import { setAuthToken } from 'redux/accountSlice';
import { baseApi } from 'redux/baseApiSlice';
import { RESET_STORE } from 'types';
import { useAppDispatch } from 'redux/hooks';

const useLogout = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    const idTokenHint = auth.user?.id_token;

    dispatch({ type: RESET_STORE });
    dispatch(setAuthToken(''));
    sessionStorage.clear();
    localStorage.clear();
    dispatch(baseApi.util.resetApiState());

    auth.clearStaleState();
    await void auth.signoutRedirect({
      id_token_hint: idTokenHint,
      post_logout_redirect_uri: window.location.origin + '/dashboard',
      redirectMethod: 'replace',
    });
  };

  return {
    handleLogout,
  };
};

export default useLogout;

export const RESET_STORE = 'RESET_STORE';

export enum UserRoles {
  Admin = 'Admin',
  Professional = 'Professional',
  Patient = 'Patient',
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  System = 'System',
}

export enum UserPermissions {
  AppointmentReadPatient = 'Appointment_Read_Patient',
  BeneficiaryRead = 'Beneficiary_Read',
  MedicationRead = 'Medication_Read',
  UserPlanRead = 'UserPlan_Read',
  BeneficiaryCreate = 'Beneficiary_Create',
  RecordReadPatient = 'Record_Read_Patient',
  PrescriptionReadPatient = 'Prescription_Read_Patient',
  ProfessionalReadPatient = 'Professional_Read_Patient',
  UserPlanUpdate = 'UserPlan_Update',
  RecordFileReadPatient = 'Record_File_Read_Patient',
  UserPlanDelete = 'UserPlan_Delete',
  UserPlanCreate = 'UserPlan_Create',
  BeneficiaryDelete = 'Beneficiary_Delete',
  AppointmentUpdate = 'Appointment_Update',
  BeneficiaryUpdate = 'Beneficiary_Update',
  RecordFileCreate = 'Record_File_Create',
  BenefitRead = 'Benefit_Read',
  AppointmentReviewRead = 'Appointment_Review_Read',
  PaymentCreate = 'Payment_Create',
  AppointmentReviewPatient = 'Appointment_Review_Patient',
  AppointmentCreate = 'Appointment_Create',
  ProfessionalReviewPatient = 'Professional_Review_Patient',
  MeetingCreate = 'Meeting_Create',
}

export enum EAppUrls {
  professionalUrl = 'https://webdev.homnics.com',
  patientUrl = 'https://portal.homnics.com',
}

export interface IPageInfo {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export enum ErrorMessagesEnum {
  NetworkError = 'There was a network error. Please check your internet connection and try again.',
  UnknownError = 'An error occured. Either there is a network issue or there is an issue with your request. We are working on this.',
  InputDetailsError = 'Something went wrong. Please check your details and try again.',
  EmailAlreadyExistsError = 'Registration failed. Email already exists. Please sign in instead.',
}

export interface User {
  firstName: string;
  lastName: string;
  imageUrl: string;
  symptoms?: string;
}
export interface UserGlobalInterface {
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  status: UserStatuses | string;
  user: User;
  type: ScheduleTypes | ProfessionalTabs;
  mode: ScheduleModes | string;
  symptoms?: string;
}

export enum ScheduleModes {
  Voice = 'voice',
  Video = 'video',
  InPerson = 'in-person',
  None = 'none',
}

export enum UserStatuses {
  Pending = 'Pending',
  Completed = 'Completed',
  Declined = 'Declined',
  Scheduled = 'Scheduled',
  Cancelled = 'cancalled',
  Offline = 'offline',
  Online = 'online',
  Read = 'read',
}

export enum ScheduleTypes {
  Consultation = 'consultation',
  Prescription = 'prescription',
  FollowUp = 'follow-up',
}

export enum UserGenderTypes {
  Male = 'Male',
  Female = 'Female',
}

export enum ProfessionalTabs {
  All = 'All',
  Clients = 'Clients',
  Employees = 'Employees',
  MedicalLicense = 'Medical License',
  MedicalId = 'Medical ID',
  MedicalNotes = 'Medical Notes',
}

export enum ProfessionalStatus {
  // From server
  Unknown = 'Unknown',
  PendingBackgroundCheck = 'PendingBackgroundCheck',
  Active = 'Active',
  BackgroundCheckFailed = 'BackgroundCheckFailed',
  Suspended = 'Suspended',
  // Within the app
  Approved = 'Approved',
  Denied = 'Denied',
  Pending = 'Pending',
}

export interface IRelationshipList {
  id: number;
  name: string;
}

export enum PatientProfileDataEnum {
  BeneficiaryNumber = 'Homnics ID',
  FirstName = 'First Name',
  LastName = 'Last Name',
  FullName = 'Full Name',
  Address = 'Location',
  Age = 'Age',
  Gender = 'Gender',
  City = 'City',
  Country = 'Country',
  DateOfBirth = 'Birth Date',
  Email = 'Email',
  PhoneNumber = 'Phone Number',
  State = 'State',
  Symptoms = 'Symptoms',
}

export enum DayOfWeekEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum MonthEnum {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export const APP_ROUTES_LIST = {
  DASHBOARD: '/dashboard',
  PROFESSIONALSLIST: '/professionals-list',
  BOOKAPPOINTMENT: '/book-appointment',
  BOOKINGSUMMARY: '/booking-summary',
  APPOINTMENTS: '/appointments',
  APPOINTMENTS_WITH_ID: '/appointments/:new',
  MEET: '/meet',
  PRESCRIPTIONS: '/prescriptions',
  PRESCRIPTIONS_WITH_ID: '/prescriptions/:id',
  SEARCH: '/search',
  ACTIVITY: '/activity',
  ACCOUNT: '/account',
  REVIEWS: '/reviews',
  REVIEW_DETAIL: '/review_detail',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  SECURITY: '/security',
  HEALTHPLANS: '/health-plans',
  CONFIRMSUBSCRIPTION: '/confirm-subscription',
  SUBSCRIPTION: '/subscription',
  BENEFICIARIESLIST: '/beneficiaries-list',
  NEWBENEFICIARY: '/new-beneficiary',
  CHANGEPASSWORD: '/change-password',
  RECORDS: '/records',
};

import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { AuthProvider } from 'react-oidc-context';
import { themeOverride } from 'themes';
import { PersistGate } from 'redux-persist/integration/react';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export interface ThemeProviderProps {
  children: React.ReactNode;
}

const onSigninCallback = () => {
  try {
    window.history.replaceState({}, document.title, '/dashboard');
  } catch (error) {
    console.error('Error during onSigninCallback:', error);
  }
};

export const userManager = new UserManager({
  authority: process.env.REACT_APP_OIDC_AUTHORITY ?? '',
  client_id: process.env.REACT_APP_CLIENT_ID ?? '',
  redirect_uri: window.location.origin + '/authentication/silent-callback',
  scope: 'openid profile offline_access',
  automaticSilentRenew: true,
  post_logout_redirect_uri: window.location.origin + '/dashboard',
  revokeTokenTypes: ['access_token', 'refresh_token'],
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export const AppProvider = ({ children }: ThemeProviderProps) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  const dark = colorScheme === 'dark';
  return (
    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              theme={
                dark ? { ...themeOverride, colorScheme: 'dark' } : themeOverride
              }
              withGlobalStyles
              withNormalizeCSS
            >
              <ModalsProvider labels={{ confirm: 'Submit', cancel: 'Cancel' }}>
                <NotificationsProvider zIndex={10000} position="bottom-center">
                  {children}{' '}
                </NotificationsProvider>
              </ModalsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </PersistGate>
      </Provider>
    </AuthProvider>
  );
};

import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
// import { AuthRoutes, PrivateRoute } from 'routes';
import { UnauthorizedPage } from 'components/UnauthorizedPage';
import { AppLayout } from 'components/AppLayout';
import { AlertFullPage } from 'components/AlertFullPage';
import { APP_ROUTES_LIST, UserRoles } from 'types';
//  todo -  for some reason - we can't lazyload this book appointment form page as well as the prescription table page. Element type is invalid. Received a promise that resolves to: undefined. Lazy element type must resolve to a class or function. Same with this PrescriptionsTable Page
import { BookAppointmentFormPage } from 'features/BookAppointment/Pages/BookAppointmentFormPage';
import { BookAppointmentLayout } from 'features/BookAppointment/BookAppointmentLayout';
import { AuthRoutes } from './AuthRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PrescriptionsTablePage } from 'features/Prescriptions/PrescriptionTablePage';

// Lazy-loaded components
const RecoverPasswordPage = lazy(() =>
  import('features/Auth/pages/RecoverPasswordPage').then((module) => ({
    default: module.RecoverPasswordPage,
  })),
);
const ResetPasswordPage = lazy(() =>
  import('features/Auth/pages/ResetPasswordPage').then((module) => ({
    default: module.ResetPasswordPage,
  })),
);
const ResetPasswordSuccessPage = lazy(() =>
  import('features/Auth/pages/ResetPasswordSuccessPage').then((module) => ({
    default: module.ResetPasswordSuccessPage,
  })),
);
const SignUpPage = lazy(() =>
  import('features/Auth/pages/SignUpPage').then((module) => ({
    default: module.SignUpPage,
  })),
);
const VerifyAccountPage = lazy(() =>
  import('features/Auth/pages/VerifyAccountPage').then((module) => ({
    default: module.VerifyAccountPage,
  })),
);
const ConfirmAccountSuccessPage = lazy(() =>
  import('features/Auth/pages/ConfirmAccountSuccessPage').then((module) => ({
    default: module.ConfirmAccountSuccessPage,
  })),
);
const DashboardPage = lazy(() =>
  import('features/Dashboard').then((module) => ({
    default: module.DashboardPage,
  })),
);

// const BookAppointmentFormPage = lazy(() =>
//   import('features/BookAppointment').then((module) => ({
//     default: module.BookAppointmentFormPage,
//   })),
// );

const ActivityPage = lazy(() =>
  import('features/Activity').then((module) => ({
    default: module.ActivityPage,
  })),
);
const AppointmentsPage = lazy(() =>
  import('features/Appointments').then((module) => ({
    default: module.AppointmentsPage,
  })),
);
const PrescriptionsPage = lazy(() =>
  import('features/Prescriptions').then((module) => ({
    default: module.PrescriptionsPage,
  })),
);

const ProfessionalsListPage = lazy(() =>
  import('features/BookAppointment/Pages/ProfessionalsListPage').then(
    (module) => ({
      default: module.ProfessionalsListPage,
    }),
  ),
);
const SearchProfessionalsPage = lazy(() =>
  import('features/SearchProfessionals').then((module) => ({
    default: module.SearchProfessionalsPage,
  })),
);

const BookingSummaryPage = lazy(() =>
  import('features/BookAppointment/Pages/BookingSummaryPage').then(
    (module) => ({
      default: module.BookingSummaryPage,
    }),
  ),
);

const ChooseHealthPlansPage = lazy(() =>
  import(
    'features/Subscription/components/HealthPlans/pages/ChooseHealthPlansPage'
  ).then((module) => ({
    default: module.ChooseHealthPlansPage,
  })),
);

const ConfirmSubscriptionPage = lazy(() =>
  import(
    'features/Subscription/components/HealthPlans/pages/ConfirmSubscriptionPage'
  ).then((module) => ({
    default: module.ConfirmSubscriptionPage,
  })),
);

const SubscriptionPage = lazy(() =>
  import('features/Subscription').then((module) => ({
    default: module.SubscriptionPage,
  })),
);

// const BeneficiariesListPage = lazy(() =>
//   import(
//     'features/Subscription/components/Beneficiaries/pages/BeneficiariesListPage'
//   ).then((module) => ({
//     default: module.BeneficiariesListPage,
//   })),
// );
// const BeneficiaryFormPage = lazy(() =>
//   import(
//     'features/Subscription/components/Beneficiaries/pages/BeneficiaryFormPage'
//   ).then((module) => ({
//     default: module.BeneficiaryFormPage,
//   })),
// );

const SettingsPage = lazy(() =>
  import('features/Settings/Pages/SettingsPage').then((module) => ({
    default: module.SettingsPage,
  })),
);

const AccountPage = lazy(() =>
  import('features/Settings/Pages/AccountPage').then((module) => ({
    default: module.AccountPage,
  })),
);
const ProfilePage = lazy(() =>
  import('features/Profile').then((module) => ({
    default: module.ProfilePage,
  })),
);

const SecurityPage = lazy(() =>
  import('features/Security').then((module) => ({
    default: module.SecurityPage,
  })),
);
const ChangePasswordPage = lazy(() =>
  import('features/Security/components/ChangePassword').then((module) => ({
    default: module.ChangePasswordPage,
  })),
);

const MeetingPage = lazy(() =>
  import('features/Meeting/page').then((module) => ({
    default: module.MeetingPage,
  })),
);
const RecordsPage = lazy(() =>
  import('features/Records').then((module) => ({
    default: module.RecordsPage,
  })),
);

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Navigate to={APP_ROUTES_LIST.DASHBOARD} />} path="/" />
      <Route element={<PrivateRoute allowedRoles={[UserRoles.Patient]} />}>
        <Route element={<AppLayout />}>
          <Route element={<DashboardPage />} path={APP_ROUTES_LIST.DASHBOARD} />
          <Route element={<BookAppointmentLayout />}>
            <Route
              element={<ProfessionalsListPage />}
              path={APP_ROUTES_LIST.PROFESSIONALSLIST}
            />
            <Route
              element={<BookAppointmentFormPage />}
              path={APP_ROUTES_LIST.BOOKAPPOINTMENT}
            />
            <Route
              element={<BookingSummaryPage />}
              path={APP_ROUTES_LIST.BOOKINGSUMMARY}
            />
          </Route>
          <Route
            element={<ChooseHealthPlansPage />}
            path={APP_ROUTES_LIST.HEALTHPLANS}
          />
          <Route
            element={<ConfirmSubscriptionPage />}
            path={APP_ROUTES_LIST.CONFIRMSUBSCRIPTION}
          />
          <Route
            element={<SubscriptionPage />}
            path={APP_ROUTES_LIST.SUBSCRIPTION}
          />
          {/* <Route element={<BeneficiariesLayout />}>
            <Route
              element={<BeneficiariesListPage />}
              path={APP_ROUTES_LIST.BENEFICIARIESLIST}
            />
            <Route
              element={<BeneficiaryFormPage />}
              path={APP_ROUTES_LIST.NEWBENEFICIARY}
            />
          </Route> */}
          <Route element={<ActivityPage />} path={APP_ROUTES_LIST.ACTIVITY} />
          <Route
            element={<AppointmentsPage />}
            path={APP_ROUTES_LIST.APPOINTMENTS}
          />
          <Route
            element={<PrescriptionsPage />}
            path={APP_ROUTES_LIST.PRESCRIPTIONS}
          />
          <Route
            element={<SearchProfessionalsPage />}
            path={APP_ROUTES_LIST.SEARCH}
          />
          <Route
            element={<PrescriptionsTablePage />}
            path={APP_ROUTES_LIST.PRESCRIPTIONS_WITH_ID}
          />
          <Route element={<MeetingPage />} path={APP_ROUTES_LIST.MEET} />
          <Route element={<RecordsPage />} path={APP_ROUTES_LIST.RECORDS} />
          <Route element={<AccountPage />} path={APP_ROUTES_LIST.ACCOUNT} />
          <Route element={<SettingsPage />} path={APP_ROUTES_LIST.SETTINGS} />
          <Route element={<SecurityPage />} path={APP_ROUTES_LIST.SECURITY} />
          <Route
            element={<ChangePasswordPage />}
            path={APP_ROUTES_LIST.CHANGEPASSWORD}
          />
          <Route element={<ProfilePage />} path={APP_ROUTES_LIST.PROFILE} />
        </Route>
      </Route>
      <Route element={<VerifyAccountPage />} path={AuthRoutes.Verify.path()} />
      <Route
        element={<ConfirmAccountSuccessPage />}
        path={AuthRoutes.VerifySuccess.path()}
      />
      <Route
        element={<ResetPasswordPage />}
        path={AuthRoutes.ResetPassword.path()}
      />
      <Route
        element={<ResetPasswordSuccessPage />}
        path={AuthRoutes.ResetPasswordSuccess.path()}
      />
      <Route
        element={<RecoverPasswordPage />}
        path={AuthRoutes.RecoverPassword.path()}
      />
      <Route
        element={<UnauthorizedPage />}
        path={AuthRoutes.Unauthorized.path()}
      />

      <Route element={<SignUpPage />} path={AuthRoutes.Register.path()} />
      <Route path="*" element={<AlertFullPage is404Error={true} />} />
    </Routes>
  );
};
